@import "variables";

.state-internally_published {
  color: $awo-red;
}


.plone-nav > li > a {
  color: #000;
  font-weight: 600 !important;;
}

.plone-breadcrumb ol a {
    color: $plone-gray-light;
}

.navbar-barceloneta {
  background-color: $awo-nav-background !important;

  a {
    color: $awo-red;
  }

  .navbar-nav li {
    background-color: $awo-nav-background;
  }
}

#portal-logo-wrapper {
  padding-bottom: 16px;
}