// Custom colors
$awo-red: #e2001a;
$awo-nav-background: #bababa;

// Plone override colors
$plone-gray-light: lighten(#000, 46.5%);
$plone-gray-lighter: lighten(#000, 80%);
$plone-link-color-on-dark: #e2e2e2;
$plone-sitenav-bg: #bababa;
$plone-default-published-color: #253183;
$plone-default-internally-published-color: $awo-red;
$plone-sitenav-hover-bg: $plone-gray-lighter;
$plone-sitenav-link-hover-bg: $plone-gray-lighter;